<template>
  <div class="map">
    <iframe width="100%" height="100%" frameborder=0 :src="href">
    </iframe>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { mallApi } from '../api'

export default {
  name: 'Map',
  data() {
    return {
      href: 'https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=2T5BZ-G5MKX-LYC4D-7LIKS-WI4CS-73BKI&referer=myapp',
    }
  },
  computed: {
    ...mapState('user', ['custMsg', 'location', 'agentConfig', 'locationCity']),
    ...mapGetters(['locationStr']),
  },
  mounted() {
    window.addEventListener('message', this.goManage, false)
  },
  destroyed() {
    window.removeEventListener('message', this.goManage, false)
  },
  created() {
    this.href = this.href + `&coord=${this.locationStr}`
  },
  methods: {
    // message事件监听
    goManage(event) {
      var loc = event.data
      if (loc && loc.module == 'locationPicker') {
        this.getInfo(loc.latlng)
      }
    },
    // 获取详细地址信息
    getInfo(val) {
      this.$jsonp('https://apis.map.qq.com/ws/geocoder/v1/?', {
        location: `${val.lat},${val.lng}`,
        key: '2T5BZ-G5MKX-LYC4D-7LIKS-WI4CS-73BKI',
        output: 'jsonp', // output必须jsonp   不然会超时
      }).then((res) => {
        if (this.agentConfig.LOCATION_SPAN_LIMIT === 'N' && this.locationCity !== res.result.ad_info.city) {
          this.$router.push({ path: '/' })
          this.$toast('不允许跨城市定位')
          return
        }

        if (res.result) {
          let location = {
            longitude: res.result.location.lng,
            latitude: res.result.location.lat,
          }
          this.$store.commit('user/SET_LOCATION', location)
          this.$store.commit('user/SET_CITYNAME', res.result.ad_info.city)
          this.$store.commit(
            'user/SET_ADDRESS',
            res.result.formatted_addresses.recommend
          )

          if (localStorage.getItem('H5token')) {
            mallApi({
              trxCode: 'XF174',
              longitude: res.result.location.lng,
              latitude: res.result.location.lat,
              cityName: res.result.ad_info.city,
              address: res.result.formatted_addresses.recommend,
              oldCityName: this.custMsg.cityName
            }).then(res => {
              this.$store.commit('user/SET_CUSTMSG', res.custMsg)
            })
          }

          this.$router.push({ path: '/' })
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.map {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
</style>
